.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../public/image.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 0;
}

.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom right, #160C2E, #1d1345);
  opacity: 0.7; /* adjust this as needed */
  z-index: -1;
}

.logo {
  max-width: 300px;
  width: 100%;
  z-index: 1;
}